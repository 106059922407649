import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageComponent } from './components/page/page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { ViewTicketsComponent } from './components/view-tickets/view-tickets.component';
import { DetailsComponent } from './components/view-tickets/details/details.component';
import { ChatsComponent } from './components/view-tickets/chats/chats.component';
import { SearchComponent } from './components/view-tickets/details/search/search.component';
import { TabsComponent } from './components/view-tickets/details/tabs/tabs.component';
import { CardsComponent } from './components/view-tickets/details/cards/cards.component';
import { EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import { NbThemeModule, NbLayoutModule, NbContextMenuModule, NbChatModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons'; 
import { DefaultModule } from './layouts/default/default.module';
import { FullwidthModule } from './layouts/fullwidth/fullwidth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import {MatRadioModule} from '@angular/material/radio';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SimpleNotificationsModule } from 'angular2-notifications';
import {MatTabsModule} from '@angular/material/tabs';
@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    TextEditorComponent,
    ViewTicketsComponent,
    DetailsComponent,
    ChatsComponent,
    SearchComponent,
    TabsComponent,
    CardsComponent,
  ],
   
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    EditorModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbContextMenuModule,
    NbChatModule,
    DefaultModule,
    FullwidthModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    FormsModule,
    MatRadioModule,
    ScrollingModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    AngularEditorModule,
    SimpleNotificationsModule.forRoot(),
    MatTabsModule

  ],
  providers: [{provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
