<br>
<!-- <mat-tab-group class="abcd"> -->

    <!-- <mat-tab> <button (click)="openStatus() ; sendStatus()">Open</button> </mat-tab>
    <mat-tab label="Reopen" (click)="reopenStatus() ; sendStatus()"></mat-tab>
    <mat-tab label="Closed" (click)="closedStatus() ; sendStatus()"></mat-tab> -->


    <!-- <mat-tab label="Open"><</mat-tab>
    <mat-tab label="Reopen" (click)="reopenStatus() ; sendStatus()"></mat-tab>
    <mat-tab label="Closed" (click)="closedStatus() ; sendStatus()"></mat-tab> -->
<!-- </mat-tab-group> -->



<div class="row">
    <div class="col" (click)="allStatus() ; sendStatus(); changeColor()" tabindex="1" id="all">All</div>
    <div class="col" (click)="openStatus() ; sendStatus(); changeOpen()" tabindex="2" id="open">Open</div>
    <div class="col" (click)="reopenStatus() ; sendStatus(); changeReopen()" tabindex="3" id="reopen">Reopen</div>
    <div class="col" (click)="closedStatus() ; sendStatus(); changeClose()" tabindex="4" id="close">Close</div>
</div>
