<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" 
integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous">

<div class="col-lg" >
   <div [ngStyle]="{background: 'url(../../../assets/background3.png)'}" style="background-repeat: no-repeat; background-position: center; background-size: cover;">

 
    <div class="row moreQuest" >

        <div class="col" >
       
           <a routerLink="/pages" class="info btn btn-primary ">Write A Ticket!</a> 

        </div>
    
    </div>
    <div class="row gapClass">
     
            <div class="col" >
                <h3 class="headerTitle">How Can We Help ?</h3>
                <input type="text" placeholder="Search for a frequently asked question" class="inputfield">     
            </div>
        </div>
        <br>
    </div> 


<div>
    <div class="row">
        <h3 class="col frequentHeader">More Options</h3>
    </div>
    <br><br>
<div class="row gapClass" style="column-gap: 30px; ">
    <div class="col" >
        <a routerLink="/pages" class="cardDesign">
           <span class="title">
            Submit Tickets
           </span> 
           <span class= "icon">
                <i class="fa fa-pencil fa-3x" aria-hidden="true" ></i>
           </span>
           
            
        </a></div>
    <div class="col"> <a [routerLink]="['/view-tickets']" class="cardDesign">
        <span class="title">
            View Tickets
           </span> 
           <span class= "icon">
                <i class="fa fa-ticket fa-3x" aria-hidden="true" ></i>
           </span>
    </a></div>
    <div class="col"> <a routerLink="/posts" class="cardDesign">
        <span class="title">
            FAQ Tickets
           </span> 
           <span class= "icon">
                <i class="fa fa-question fa-3x" aria-hidden="true" ></i>
           </span>
    </a></div>

  
</div>
    
</div>

<br><br>

<div id="fqaPart" class=" gapClass">

    <div class="row">
        
       
            <div class="head col col-md-4">
                <h3 class="frequentHeader">Frequently Asked Question</h3>   
            </div>
        
        <div class="col col-md-1">
            <div class="vl"></div></div>
        <div class="col">
            <div class="row divPart" style="margin-top: 50px;"><a routerLink="/posts" class="link"> Where am I?</a> </div>
            <div class="row divPart"> <a routerLink="/posts"  class="link"> What's the use of the tickets?</a> </div>
            <div class="row divPart"> <a routerLink="/posts"  class="link"> How to create a ticket?</a> </div>
            <div class="row divPart"> <a routerLink="/posts"  class="link"> Who will be able to see my ticket?</a> </div>
            <div class="row divPart"> <a routerLink="/posts"  class="link"> Who can chat with me?</a> </div>
            <div class="row divPart"> <a routerLink="/posts" class="col moreQuest link" >More Questions</a> </div>
            </div>
        
  
</div>

</div>

<br><br>
</div>
