<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" 
integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous">

 
<div class="bodySide grid-container" >

<div class="navigation bodySide grid-item item1" >
   

    <ul>
        <li>
            <a class="hill" routerLink="/home" routerLinkActive="selected">
                <span class= "icon"><i class="fa fa-home" aria-hidden="true"></i></span>
                <span class="title">Home</span>
            </a>
        </li>

        <li>
            <a class="hill" routerLink="/pages" routerLinkActive="selected">
                <span class= "icon"><i class="fa fa-ticket" aria-hidden="true"></i></span>
                <span class="title">Create a Ticket</span>
            </a>
        </li>

        <li>
            <a class="hill"  [routerLink]="['/view-tickets']" routerLinkActive="selected">
                <span class= "icon"><i class="fa fa-archive" aria-hidden="true"></i></span>
                <span class="title">List Tickets</span>
            </a>
        </li>

    </ul>
</div>



<div class="toggle item3 grid-item" (click)="toggleMenu()"></div>
</div>