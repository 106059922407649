import { Element, IfStmt } from '@angular/compiler';
import { Component } from '@angular/core';
import { SharedServiceService } from 'src/app/shared-service.service';
import { TicketServiceService } from 'src/app/ticket-service.service';


@Component({
  selector: 'app-chats',
  styleUrls: ['./chats.component.scss'],
  templateUrl: './chats.component.html',
  inputs: ["tickets"]
})
export class ChatsComponent {
  emailName;
  isLoad: boolean;
  tickerId: number;
  currentPage: number;
  desc: number = 1;
  tickets;
  messages: any[];
  constructor(private selectedTicket: TicketServiceService, private emailData: SharedServiceService) {
    this.selectedTicket.selectedTicketData.subscribe((data: any) => {
      this.messages = [];
      if (data) {
        this.messages.push({
          text: data.body,
          date: new Date(),
          reply: false,
          user: {
            name: 'System',
          },
        });

      }
    })

    this.selectedTicket.selectedTicket.subscribe((message: number) => {
      this.tickerId = message;
      this.loadTicketInfo();
    })
    // this.emailData.emailSource.subscribe((email:string)=>{
    //   this.emailName = email
    // })
  }

  getEmail() {
    this.emailName = localStorage.getItem('email')
    console.log(this.emailName)
  }

  loadTicketInfo() {
    this.isLoad = true;
    this.selectedTicket.getChats(this.tickerId, this.currentPage).subscribe((result: any) => {
      this.isLoad = false;
      result.chats.forEach(element => {
        this.messages.push({
          text: element.body,
          date: element.created_at,
          files: !!element.file ? [{
            url: element.fullurl,
            type: "image/png",
            icon: 'file-text-outline',
          }] : [],
          type: !!element.file ? 'file' : "text",
          reply: element.user.email != this.emailName ? false : true,
          user: {
            name: element.user.name,
          },
        });
        console.log(element.user, 'aaa')
      });
    });
  }


  // checkUserLoggedIn(){
  //   this.isLoad = true;
  //   this.selectedTicket.getChats(this.tickerId, this.currentPage).subscribe((result: any) => {
  //     this.isLoad = false;
  //     result.chats.forEach(element => {
  //       this.messages.push({
  //         text: element.body,
  //         date: element.created_at,
  //         files: !!element.file ? [{
  //           url: element.fullurl,
  //           type: "image/png",
  //           icon: 'file-text-outline',
  //         }] : [],
  //         type: !!element.file ? 'file' : "text",
  //         reply: element.user.email == this.emailName ? true : false,
  //         user: {
  //           name: element.user.name,
  //         },
  //       });
  //     });
  //   });
  // }



  ngOnInit(): void {
    this.currentPage = 1;
    this.isLoad = true;
    this.messages = [
    ];
    this.getEmail()
  }


  getDesc() {
    this.selectedTicket.gettickets(this.desc).subscribe((data) => {
      this.tickets = data['data'][0]['body'];
      // console.log('desc:', this.tickets)
    })
  }

  sendMessage(event) {
    const files = !event.files ? [] : event.files.map((file) => {
      return {
        url: file.src,
        type: file.type,
        icon: 'file-text-outline',
      };

    });
    files.forEach(element => {
      let formData: FormData = new FormData();
      formData.set("ticket_id", `${this.tickerId}`);
      formData.append('file', event.files[0], element.name);
      this.selectedTicket.postChats(formData).subscribe(res => {
        this.messages.push({
          text: event.message,
          date: new Date(),
          files: element,
          type: 'file',
          reply: true,
          user: {
            name: "Me",
          },
        });
      });

    });
    this.selectedTicket.postChats({ "ticket_id": this.tickerId, "body": event.message, }).subscribe(res => {
      this.messages.push({
        text: event.message,
        date: new Date(),
        files: files,
        type: 'text',
        reply: true,
        user: {
          name: "Me",
        },
      });
    });

  }
}