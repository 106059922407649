import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/_models/User';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
currentUser:User;
userName :string ="SignIn"
  constructor(private router:Router) { }

  ngOnInit(): void {
   var localUser= localStorage.getItem("user")
   if(localUser)
   {
    //  console.log("User");
    this.currentUser=JSON.parse(localUser);
    // console.log(this.currentUser);
    this.userName = "Logout";
    
  }
  }
  logout()
  {
    localStorage.clear();
    this.router.navigate(['/signin']);
    
  }

}
