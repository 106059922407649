import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl, environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreateTicketService {

  constructor(private http: HttpClient) { }
  createTicket(data): Observable<any> {
  //  console.log('server here');
    let response = this.http.post(`${baseUrl}ticket`, data,{
      headers:{"Authorization":`Bearer  ${environment.token}`}
    });
    return response;
  }
}
