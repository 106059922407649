import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageComponent } from './components/page/page.component';
import { ViewTicketsComponent } from './components/view-tickets/view-tickets.component';
import { DefaultComponent } from './layouts/default/default.component';
import { FullwidthComponent } from './layouts/fullwidth/fullwidth.component';
import { HomeComponent } from './modules/home/home.component';
import { PostsComponent } from './modules/posts/posts.component';
import { SigninComponent } from './modules/signin/signin.component';


const routes: Routes = [{
  
  path:'',
  component:DefaultComponent,
  children:[{
    path:'home',
    component:HomeComponent
  },{
    path:'posts',
    component:PostsComponent
  },
  {
     path: 'view-tickets',
     //path: 'view-tickets/:id',
     component: ViewTicketsComponent
   },
  {
   // path: 'view-tickets',
    path: 'view-tickets/:id',
    component: ViewTicketsComponent
  },{
    path:'pages',
    component:PageComponent,
  },
 
]},
{
  path:'',
  component:FullwidthComponent,
  children:[{
      path:'signin',
      component:SigninComponent,
    }]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

  
}
