import { Component, OnInit } from '@angular/core';
import { StatusService } from 'src/app/status.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  clickStatus : string ;

  constructor(private sharedStatus: StatusService) { }

  ngOnInit(): void {
    this.clickStatus='all';
    document.getElementById("all").style.background = "#1b262c"; 
    document.getElementById("all").style.color = "white"; 
  }

  allStatus(){
    this.clickStatus='all';
    
  }

  openStatus(){
    this.clickStatus='Open';
   // console.log('khefsgdvc')
  }

  closedStatus(){
    this.clickStatus='Close';
  }

  reopenStatus(){
    this.clickStatus='Reopen';
  }

  changeColor(){
     document.getElementById("all").style.background = "#1b262c"; 
     document.getElementById("all").style.color = "white"; 
     document.getElementById("open").style.background = "white"; 
     document.getElementById("open").style.color = "#1b262c";
     document.getElementById("close").style.background = "white"; 
     document.getElementById("close").style.color = "#1b262c"; 
     document.getElementById("reopen").style.background = "white"; 
     document.getElementById("reopen").style.color = "#1b262c"; 

    }


    changeOpen(){
      document.getElementById("open").style.background = "#1b262c"; 
      document.getElementById("open").style.color = "white"; 
      document.getElementById("all").style.background = "white"; 
      document.getElementById("all").style.color = "#1b262c";
      document.getElementById("close").style.background = "white"; 
      document.getElementById("close").style.color = "#1b262c"; 
      document.getElementById("reopen").style.background = "white"; 
      document.getElementById("reopen").style.color = "#1b262c"; 
 
     }

     changeClose(){
      document.getElementById("close").style.background = "#1b262c"; 
      document.getElementById("close").style.color = "white"; 
      document.getElementById("open").style.background = "white"; 
      document.getElementById("open").style.color = "#1b262c";
      document.getElementById("all").style.background = "white"; 
      document.getElementById("all").style.color = "#1b262c"; 
      document.getElementById("reopen").style.background = "white"; 
      document.getElementById("reopen").style.color = "#1b262c"; 
 
     }

     changeReopen(){
      document.getElementById("reopen").style.background = "#1b262c";
      document.getElementById("reopen").style.color = "white"; 
      document.getElementById("open").style.background = "white"; 
      document.getElementById("open").style.color = "#1b262c";
      document.getElementById("close").style.background = "white"; 
      document.getElementById("close").style.color = "#1b262c"; 
      document.getElementById("all").style.background = "white"; 
      document.getElementById("all").style.color = "#1b262c"; 
 
     }



  sendStatus(){
    this.sharedStatus.statusSource.next(this.clickStatus);
    // console.log("jgjhfgwjhgfs ",this.clickStatus)
  }



}
