import { Component, ViewChild } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TicketServiceService } from './ticket-service.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TicketingSystem';

  constructor(private router: Router, private service: NotificationsService, private ticketSerivces: TicketServiceService) {
  
  }

  ngOnInit(): void {
    this.ticketSerivces.notification.subscribe((msg) => {
      setTimeout(()=>{
        if (msg)
        {
          this.onSuccess();
          
        }
      },1000)
    })
  }

  onSuccess (){
    // console.log("Here one button")
    this.service.success('Ticket added', 'Success', {
      position: ['bottom', 'right'],
      timeOut: 5000,
      animate:'fade',
    });
    
  }

  onError (){
    this.service.error('Error adding the ticket', 'Error', {
      position: ['bottom', 'right'],
      timeOut: 3000,
      animate:'fade',
      showProgressBar:true
    })
  }



}
