<editor


id="editor"
[init]="{
    selector:'textarea',
    content_style:'body{background: rgba(255, 255 , 255,1); color:black; border-radius: 40px}',
    content_css:'dark',
    skin: 'oxide-dark',
    height:350,
    base_url:'/tinymce',
    suffix:'.min',
    plugins: 'lists advlist wordcount insertdatetime textcolor image link',
    statusbar:'wordcount | insertdatetime_element',
    textcolor_rows: '4',
    file_browser_callback_types: 'file image media',
    branding: false,
    insertdatetime_element: true,
    images_upload_url: './js/tinymce/postAcceptor.php',
    images_upload_base_path: '/some/basepath',
    images_upload_credentials: true,
    automatic_uploads: false,
    toolbar:'undo redo | formatselect | bold italic | bullist numlist outdent indent | forecolor backcolor | image | link'
   
  }"
>

</editor>