import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { DataService } from 'src/app/dataservice.service';
import { TicketServiceService } from 'src/app/ticket-service.service';

@Component({
  selector: 'app-view-tickets',
  templateUrl: './view-tickets.component.html',
  styleUrls: ['./view-tickets.component.scss']
})
export class ViewTicketsComponent implements OnInit {
  page: number = 1;
  selectedTicket: number ;
 // statusMessage: string;
  allTickets:[]=[];
  constructor(private ticketapi: TicketServiceService, private sharedData: DataService, private service: NotificationsService,) { 
    // this.sharedData.messageSource.subscribe((message: string)=>{
    //   this.statusMessage = message;
    //   console.log('message received', message);
    
    // })
   
    // console.log("this Is the constructor", this.statusMessage )
  }



  ngOnInit(): void {
    this.loadTicket();
   // this.statusMessage =""
  }


  loadTicket() {
    this.ticketapi.gettickets(this.page).subscribe(res => {
     let temp:[]= res.data;
      this.allTickets=[
        ...this.allTickets,
        ...temp ,
      ];
    })
  }
}
