<div fxLayoutAlign="center center" fxFlexFill class="main-div">
    <mat-card fxFlex="25">
        <mat-toolbar color="primary" class="abcd">Login</mat-toolbar>
        <form fxLayoutAlign="stretch" fxLayout="column" class="login-form" [formGroup]="formGroup" (ngSubmit)="loginProcess()">
            <mat-form-field>
                <mat-label>Username</mat-label>
                <input matInput placeholder="Username" id="email" formControlName="email">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput type="password" placeholder="Password" formControlName="password">
            </mat-form-field>
            <button mat-button type="submit">Login</button>
            <button mat-button type="button" (click)="onLogout()" >Logout</button>
        </form>

    </mat-card>
</div>