<!-- <header fxLayoutAlign="space-between center">
    <div>LOGO</div>
    <div fxLayout="row" fxLayoutGap="20px">
        <a routerLink="/">Home</a>
        <a routerLink="posts">Posts</a>
        <a routerLink="login">Login</a>
    </div>
</header> -->


<div class="navbar">

    <a *ngIf="!currentUser"  routerLink="/signin" >Sign in</a>
    <a *ngIf="currentUser" (click)="logout()" >Logout</a>
    <a routerLink="/posts">Posts</a>
    <a routerLink="/home">Home</a>
   
  </div>
  