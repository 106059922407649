import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {Router} from "@angular/router";
import { TicketServiceService } from 'src/app/ticket-service.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  page:number=1;
  tickets: number;

  constructor(private router:Router, private ticketapi: TicketServiceService) { }

  ngOnInit(): void {
    environment.token= localStorage.getItem('token');
    if(!environment.token){
      this.router.navigate(['signin']);
    }

    this.getTicketId();

  }


  getTicketId(){
      this.ticketapi.gettickets(this.page).subscribe((data)=>{
      this.tickets = data['data'][0]['id'];


    })
  }

}
