

    <div [ngClass]="statusReceived=='all'?'open':'closed'" (click)="onSelect(ticket);" class="container active" >


        <div *ngIf="ticket.open_by" class="row"><img *ngIf="ticket.open_by.fullphoto" [src]=ticket.open_by.fullphoto
                class="img">
            <div class="title">{{ticket.open_by.name}}</div>
        </div>
        <div class="line"></div>
        <div><span class="ticketInfo"> Title:  </span>  <span class="status-2 text"> {{ticket.title}}</span></div>
        <br>
        <div><span class="ticketInfo"> Description:  </span> <span class="status-2 text"> {{ticket.body}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Status: </span> <span class="status-2">{{ticket.status}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Priority: </span> <span class="status-2">{{ticket.priority}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Type: </span> <span class="status-2">{{ticket.dept}}</span></div>
        <br>
        <div><span class="ticketInfo"> Created: </span> <span class="status-2">{{ticket.created_at | date}}</span></div>
        <br>
        <div class="divDesign" *ngIf="ticket.status!='Close'" (click)="closeTicket(ticket.id)"> CLOSE TICKET</div>
    </div>


    <div *ngIf="onClickCard(ticket.status)=='Open'" [ngClass]="statusReceived=='Open'?'open':'closed'" 
            
    (click)="onSelect(ticket);" class="container active">

        <div *ngIf="ticket.open_by" class="row"><img *ngIf="ticket.open_by.fullphoto" [src]=ticket.open_by.fullphoto
                class="img">
            <div class="title">{{ticket.open_by.name}}</div>
        </div>
        <div class="line"></div>
        <div><span class="ticketInfo"> Title:  </span>  <span class="status-2 text"> {{ticket.title}}</span></div>
        <br>
        <div><span class="ticketInfo"> Description:  </span> <span class="status-2 text"> {{ticket.body}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Status: </span> <span class="status-2">{{ticket.status}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Priority: </span> <span class="status-2">{{ticket.priority}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Type: </span> <span class="status-2">{{ticket.dept}}</span></div>
        <br>
        <div><span class="ticketInfo"> Created: </span> <span class="status-2">{{ticket.created_at | date}}</span></div>
        <br>
        <div class="divDesign" (click)="closeTicket(ticket.id)"> CLOSE TICKET</div>
    </div>


    <div *ngIf="onClickCard(ticket.status)=='Reopen'"
        [ngClass]="statusReceived=='Reopen'?'open':'closed'" (click)="onSelect(ticket);" class="container active">

        <div *ngIf="ticket.open_by" class="row"><img *ngIf="ticket.open_by.fullphoto" [src]=ticket.open_by.fullphoto
                class="img">
            <div class="title">{{ticket.open_by.name}}</div>
        </div>
        <div class="line"></div>
        <div><span class="ticketInfo"> Title:  </span>  <span class="status-2 text"> {{ticket.title}}</span></div>
        <br>
        <div><span class="ticketInfo"> Description:  </span> <span class="status-2 text"> {{ticket.body}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Status: </span> <span class="status-2">{{ticket.status}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Priority: </span> <span class="status-2">{{ticket.priority}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Type: </span> <span class="status-2">{{ticket.dept}}</span></div>
        <br>
        <div><span class="ticketInfo"> Created: </span> <span class="status-2">{{ticket.created_at | date}}</span></div>
        <br>
        <div class="divDesign" (click)="closeTicket(ticket.id)"> CLOSE TICKET</div>

    </div>

    <div *ngIf="onClickCard(ticket.status)=='Close'"
        [ngClass]="statusReceived=='Close'?'open':'closed'" (click)="onSelect(ticket);" class="container active">

        <div *ngIf="ticket.open_by" class="row"><img *ngIf="ticket.open_by.fullphoto" [src]=ticket.open_by.fullphoto
                class="img">
            <div class="title">{{ticket.open_by.name}}</div>
        </div>
        <div class="line"></div>
        <div><span class="ticketInfo"> Title:  </span>  <span class="status-2 text"> {{ticket.title}}</span></div>
        <br>
        <div><span class="ticketInfo"> Description:  </span> <span class="status-2 text"> {{ticket.body}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Status: </span> <span class="status-2">{{ticket.status}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Priority: </span> <span class="status-2">{{ticket.priority}}</span></div>
        <br>
        <div> <span class="ticketInfo"> Type: </span> <span class="status-2">{{ticket.dept}}</span></div>
        <br>
        <div><span class="ticketInfo"> Created: </span> <span class="status-2">{{ticket.created_at | date}}</span></div>
        <br>
    </div>
