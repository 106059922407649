<app-header flexLayout="column"></app-header>

<div flexLayout="row" fxFlex>
    
    <div fxFlex="100" class="container">

        <router-outlet></router-outlet>
    </div>
</div>

<app-footer flexLayout="column"></app-footer>

