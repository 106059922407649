import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl, environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TicketServiceService {
  selectedTicket = new BehaviorSubject<number>(0);
  selectedTicketData = new BehaviorSubject<any>(null); // or null, type any can be changed with a interface that follow ur contact object
  private status = new BehaviorSubject<string>('');
  notification = new BehaviorSubject<string>(null);
  constructor(private http: HttpClient) { }

  checkUser() {

  }

  gettickets(page): Observable<any> {
    let response = this.http.get(`${baseUrl}ticket`, {
      headers: { "Authorization": `Bearer  ${environment.token}` }
    });
    return response.pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  closeTickets(ticketId: number): Observable<any> {
    let response = this.http.delete(`${baseUrl}ticket/${ticketId}`, {
      headers: { "Authorization": `Bearer  ${environment.token}` }
    });
    //  console.log('this is the response: ', response)
    return response;
  }

  getChats(ticketId: number, page): Observable<any> {
    let response = this.http.get(`${baseUrl}ticket/${ticketId}`, {
      headers: { "Authorization": `Bearer  ${environment.token}` }
    });
    return response.pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  postChats(body: any): Observable<any> {
    // console.log('server here');
    let response = this.http.post(`${baseUrl}ticketchats`,
      body

      , {
        headers: { "Authorization": `Bearer  ${environment.token}` }
      });
    return response.pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  
  activeTicket(): Observable<number> {
    return this.selectedTicket.asObservable();
  }
  changeData(ticketId: number) {
    this.selectedTicket.next(ticketId);
  }

  ticketStat(status: string) {
    this.status.next(status);
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      console.log('this is e.e.m', error.error.message)
    } else {
      // server-side error
      errorMessage = `\nSomething went wrong. Please try again`;
      console.log('this is e.s', error.message)
      console.log('this is e.m', error.message)
    }
    window.alert(errorMessage);
    console.log('this is error message', errorMessage)
    return throwError(errorMessage);
  }
}
