<app-header flexLayout="column"></app-header>
<div flexLayout="row" fxFlex >
    <div fxFlex= "{{closed == true ? 1: 17}}">
        <app-sidebar fxFlex (colp)="toggleFunction($event)"></app-sidebar>
    </div>
    <div fxFlex= "{{closed == true ? 90: 80}}" class="container">

        <router-outlet></router-outlet>
    </div>
</div>
<!-- 
<app-footer flexLayout="column"></app-footer> -->