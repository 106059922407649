<nb-layout class="container">
  <nb-layout-column *ngIf="!isLoad" class="container" >
    <nb-chat title="Chat" size="large">
      <nb-chat-message
        *ngFor="let msg of messages"
        [type]="msg.type"
        [message]="msg.text"
        [reply]="msg.reply"
        [sender]="msg.user.name"
        [date]="msg.date"
        [files]="msg.files"
        [quote]="msg.quote"
        [latitude]="msg.latitude"
        [longitude]="msg.longitude"
        [avatar]="msg.user.avatar"
      >
      </nb-chat-message>
      <nb-chat-form (send)="sendMessage($event)" [dropFiles]="true" class="aaaabb"> </nb-chat-form>
    </nb-chat>

  </nb-layout-column>
  <nb-layout-column *ngIf="isLoad" class="container" >
    <nb-chat title="Loading" size="large">
      <nb-chat-message
      *ngFor="let msg of []"
      [type]="msg.type"
      [message]="msg.text"
      [reply]="msg.reply"
      [sender]="msg.user.name"
      [date]="msg.date"
      [files]="msg.files"
      [quote]="msg.quote"
      [latitude]="msg.latitude"
      [longitude]="msg.longitude"
      [avatar]="msg.user.avatar"
    >
    </nb-chat-message>
    </nb-chat>
  </nb-layout-column>
</nb-layout>