import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatusService } from 'src/app/status.service';
import { TicketServiceService } from 'src/app/ticket-service.service';


@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
  statusReceived: string;
  ticketClicked;
  tickets;
  bgColor: string = 'yellow';
  active: string = "container";
  @Input()
  ticket: any;
  @Input()
  isActive: boolean = false;
 
  constructor(private appData: TicketServiceService, private sharedStatus: StatusService) {
    this.sharedStatus.statusSource.subscribe((message: string) => { this.statusReceived = message })
    this.appData.selectedTicket.subscribe((seleted:number)=>{
      if(this.ticket)
    {  this.active=(seleted==this.ticket.id)?"container active" : "container";
   
    }
    })
  }

  ngOnInit(): void {
    this.active = this.isActive ? "container active" : "container";
    this.statusReceived = 'all'
  }

  closeTicket(data){
    this.appData.closeTickets(data).subscribe((dataTicket)=>{
      dataTicket = data
    })

  }

  changeTableRowColor(idx: any) {
    if (this.ticketClicked === idx) {
    } else this.ticketClicked = idx;
  }

  onClickCard(data) {

    this.appData.changeData

    return data;
  }
  onSelect(data) {
    this.appData.selectedTicket.next(data.id);
    this.appData.selectedTicketData.next(data);
  }
  extractStatus() {

    this.appData.ticketStat

  }

  onIdClick(data) {

    this.appData.selectedTicket.next(data.id);
    this.appData.selectedTicketData.next(data);
  }




}
