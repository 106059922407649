import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarComponent } from 'src/app/shared/components/sidebar/sidebar.component';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  closed: boolean = true;

  constructor(private router: Router) {   }

  ngOnInit(): void {
    this.router.navigate(["home"]);
    this.closed = true;
  }

  // onSuccess() {
  //   // console.log("Here one button")
  //   this.service.success('Ticket added', 'Success', {
  //     position: ['bottom', 'right'],
  //     timeOut: 5000,
  //     animate: 'fade',
  //   });

  // }

  // onError() {
  //   this.service.error('Error adding the ticket', 'Error', {
  //     position: ['bottom', 'right'],
  //     timeOut: 3000,
  //     animate: 'fade',
  //     showProgressBar: true
  //   })
  // }
  toggleFunction($event) {
    this.closed = $event;
    // console.log("hello", this.closed);
  }


}
