<script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.6.9/angular.min.js"></script>
<div class="text ">Create an new ticket</div>
<div class="container col-lg">
    <div class="form">

<simple-notifications></simple-notifications>
        <form action="" [formGroup]="ticketformGroup" ngNativeValidate #form="ngForm" >
            <br>
            
            <label for="title col-md" class="headings">Title of the ticket <span style="color: red;">*</span></label>
            <br>
            <br>

            <input type="text" id="userInput" (change)="maxDetector()" class="col-md-3 inputsUsed" formControlName="title" maxlength="100" required>
            <h6 style="color: red;" *ngIf="show==true">You Reached the Maximum Length</h6>
          <br><br><br>
            <p class="prity headings">Priority </p>
            <div class="row" >
                <label class="container1">
                    <input type="radio" value="1" formControlName="priority_id" >
                    <span class="checkmark"></span>
                    High
                </label>
                <label class="container1">
                    <input type="radio" checked="checked" value="2" formControlName="priority_id">
                    <span class="checkmark"></span>
                    Medium
                </label>
                <label class="container1">
                    <input type="radio" value="3" formControlName="priority_id" >
                    <span class="checkmark"></span>
                    Low
                </label>
            </div>
            <br>
            <label class="type headings">Type</label>
            <br>
            <br>

            <mat-form-field appearance="outline" class="inputsUsed" >
                <select matNativeControl formControlName="dept" > 
                    <option value="BugReport" [selected]="'Bug Report'">Bug Report</option>
                    <option value="CustomerSupport">Customer Support</option>
                    <option value="Complaints">Complaints</option>
                    <option value="Other">Other</option>
                </select>
            </mat-form-field>
            <br>
            <div class="type headings">Description</div>
            

            <div class="textEdit">
              
           
                <textarea rows="10" cols="100" style="padding-left:10px; padding-top: 10px;" formControlName="body">
                    
                </textarea>
            </div>
         
            <br>
            <br>
            <br>
            <button class="marginButton" type="submit" (click)="ticketCreation(); " [disabled]="!form.valid" style="height: 30px;">Send</button>  
            <br>
            <br>

        </form>
    </div>
</div>