import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthServiceService } from 'src/app/auth-service.service';
import {Router} from '@angular/router'
import { SharedServiceService } from 'src/app/shared-service.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  formGroup: FormGroup;
  emailUser: any;

  constructor(private authServive:AuthServiceService,private router:Router, private chatEmail: SharedServiceService) { 
  }

  ngOnInit() {
    this.initForm(); 
  }

  initForm(){
    this.formGroup = new FormGroup({
       email: new FormControl('', [Validators.required]),
       password: new FormControl('', [Validators.required ])
    })
  }

  loginProcess(){
    try {

  
      
      if(this.formGroup.valid){
        this.authServive.login(this.formGroup.value).subscribe(result=>{
          if(result.success){
            console.log(result.success.token);
            this.authServive.updateToken(result.success.token,result.user);
            this.router.navigate(['home']);
             var emailUser = (<HTMLInputElement>document.getElementById('email')).value
             this.authServive.getUserEmail(emailUser);
             console.log('checking the email user ',emailUser)
             this.getEmailForChat(this.emailUser)
          } else{
            alert(result.message)
          }
        })
      }
      
    } catch (error) {

    console.log("this is the error",error)
      
    }

  }

  getEmailForChat(email){
    this.chatEmail.emailSource.next(email)
  }

  onLogout()
  {
    this.authServive.login(this.formGroup.value);
  }

}
