import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  messageSource: BehaviorSubject <string> = new BehaviorSubject('Add Media');

  constructor() { }
}
