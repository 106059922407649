import { baseUrl, environment } from "./../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { User } from './_models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
user:User;
  constructor(private http: HttpClient) { }

  login(data): Observable<any> {
    // console.log('server here');
     let response = this.http.post(`${baseUrl}login`, data);
     return response.pipe(
      retry(1),
      catchError(this.handleError)
    );
   }

   handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      console.log('this is e.e.m', error.error.message)
    } else {
      // server-side error
      errorMessage = `\nSomething went wrong. Please try again`;
      console.log('this is e.s', error.message)
      console.log('this is e.m', error.message)
    }
    window.alert(errorMessage);
    console.log('this is error message', errorMessage)
    return throwError(errorMessage);
  }


  updateToken(newToken,user:User) {
    environment.token = newToken;
    localStorage.setItem('token',environment.token);
    localStorage.setItem('user',JSON.stringify(user));
  }

  getUserEmail(userInput){
    localStorage.setItem('email', userInput)
  }

}