import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TicketServiceService } from 'src/app/ticket-service.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Output() colp = new EventEmitter<boolean>();
  tickets;

  closed: boolean = true;
  PName: string = 'page'
  page: number = 1;

  constructor(private router: Router, private ticketapi: TicketServiceService) { }


  ngOnInit(): void {

    this.router.navigate(["home"]);
    //this.getTicketId();


  }

  getTicketId() {
    this.ticketapi.gettickets(this.page).subscribe((data) => {
      this.tickets = data['data'][0]['id'];
      // console.log(this.tickets)
    })
  }



  toggleMenu() {

    let navigation = document.querySelector('.navigation');
    navigation.classList.toggle('active');
    this.closed = !this.closed;
    this.colp.emit(this.closed);

  }

}
