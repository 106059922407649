import { Component, Input, OnInit } from '@angular/core';
import { TicketServiceService } from 'src/app/ticket-service.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
@Input() 
tickets : [];
selectedTicket: number ;
  constructor(ticketData:TicketServiceService) {
    ticketData.selectedTicket.subscribe((data:number)=>{
      //console.log("ticket adata",data);
      this.selectedTicket=(data)
  
    })
   }

  ngOnInit(): void {
    
  }
  GetChildData(data){  
    this.selectedTicket=(data);  
    // console.log(this.selectedTicket)
 }  
}
