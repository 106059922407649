<div>


  <div class="row">
    <div class="col-4">
     <app-details [tickets]="allTickets"></app-details>
    </div>
    <div class="col-8">
     <app-chats data-tickets="asdgb"></app-chats>
    </div>

  </div>
</div>
<!-- 
<simple-notifications></simple-notifications> -->