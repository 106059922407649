import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { title } from 'process';
import { AsyncSubject, Subject } from 'rxjs';
import { CreateTicketService } from 'src/app/create-ticket.service';
import {NotificationsService} from 'angular2-notifications';
import tinymce from 'tinymce';
import { DataService } from 'src/app/dataservice.service';
import { TicketServiceService } from 'src/app/ticket-service.service';
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})



export class PageComponent implements OnInit {
  ticketformGroup: FormGroup;
  page:number=1;
  show:boolean = false;
  tickets;
  @Input () inputuser: string =""; 

  @Input () ticket: any;

  @Output() change: EventEmitter <any> = new EventEmitter <any>();

  constructor(private authService: CreateTicketService, private router:Router, private service: NotificationsService, private sharedData: DataService, private ticketapi: TicketServiceService,) { }

  ngOnInit(): void {
    this.initForm();
  }

  // sendMessage(message): void{
  //   this.sharedData.messageSource.next(message);
  // }
 

  onSuccess (){
    // console.log("Here one button")
    this.service.success('Ticket added', 'Success', {
      position: ['bottom', 'right'],
      timeOut: 5000,
      animate:'fade',
    });
    
  }

  onError (){
    this.service.error('Error adding the ticket', 'Error', {
      position: ['bottom', 'right'],
      timeOut: 3000,
      animate:'fade',
      showProgressBar:true
    })
  }

  saveContent(){
    this.inputuser = tinymce.activeEditor.getContent();
   
  }

  initForm(){
    this.ticketformGroup = new FormGroup({
       title: new FormControl('', [Validators.required]),
       priority_id: new FormControl('2'),
       dept: new FormControl('Bug Report'),
       body: new FormControl('')
       
    })
  }


  ticketCreation(){
    // console.log(this.ticketformGroup.value);
    //this.initForm();
    if(this.ticketformGroup.valid){
      this.authService.createTicket(this.ticketformGroup.value).subscribe(result=>{
        if(result.status){
          //this.sendMessage("success");
   
          this.onSuccess();
          this.router.navigate(['view-tickets']).then((val) => {  
              this.ticketapi.notification.next("success")
          });
        
        } else{
          this.onError()
          alert("Something went wrong")
         
        }
      })
    }
  }

  getTicketId(){
    this.ticketapi.gettickets(this.page).subscribe((data)=>{
      this.tickets = data['data'][1]['id'];
      this.tickets = this.tickets +1;
      // console.log(this.tickets)
    })

    
  }


  maxDetector(){
    var inputValue = (<HTMLInputElement>document.getElementById('userInput')).value.length;
    console.log (inputValue)
    if (inputValue == 100){
      this.show = true;
    }else{
      this.show= false;
    }
  }

}