<div class="something col-sm-6">
    <form [formGroup]="loginForm">
        <div class="form-group">
            <label>Email Address</label>
            <input type="email" class="form-control" formControlName="email" placeholder="Enter email">
            <span class="error" *ngIf='email.invalid && email.touched'>Email is required</span>
        </div>

        <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control" formControlName="password" placeholder="Password">
            <span class="error" *ngIf='email.invalid && email.touched'>Password is required</span>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
    </form>
</div>